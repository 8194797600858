import React, { useEffect } from "react"
import useToggleFaq from "../hooks/useToggleFaq"

import AccMinus from "../assets/img/acc-minus.inline.svg"
import AccPlus from "../assets/img/acc-plus.inline.svg"
import { Link } from "gatsby"

const TechnologyAccordian = ({ data, isEdtech }) => {
    const { isExpanded, getToggleMeClass } = useToggleFaq()

    useEffect(() => {
        getToggleMeClass(0)
    }, [])

    return (
        <section id="faq" className="">
            <div className="container px-0">
                <div className="">
                    <div className="">
                        {data.map(({ title, description }, idx) => (
                            <div
                                key={idx}
                                className={`${idx === data.length - 1 ? "" : "border-b border-[#D9D9D9]"
                                    } py-6 px-10 max-sm:px-3 ${isExpanded[idx] ? " border-[0]  mt-5" + (isEdtech ? " bg-[#fff] border-transparent rounded-[20px]" : " bg-[#F8F7F3] border-transparent rounded-[20px]") : ""}`}
                            >
                                <span
                                    onClick={() => {
                                        getToggleMeClass(idx)
                                    }}
                                    className="flex py- items-center justify-between md:text-xl text-base select-none font-gorditamedium text-black relative pr-[3.5rem] cursor-pointer"
                                >
                                    {title}
                                    <span
                                        id="showMore"
                                        className={`absolute right-0 top-0 w-6 h-6 flex justify-center items-center cursor-pointer`}
                                    >
                                        {isExpanded[idx]
                                            ? <AccMinus />
                                            : <AccPlus />
                                        }
                                    </span>
                                </span>
                                <p>{isExpanded ? isExpanded[idx] : "ffff"}</p>
                                <div
                                    id={`desc-${idx}`}
                                    className={`mt-[0.75rem] pr-5  ${isExpanded[idx] ? "" : "hidden"}`}
                                >
                                    {description.map((chunk, idx) => chunk[0] ? <Link key={idx} to={chunk[0]} className="text-[#0B57D0] underline italic hover:text-primary font-gorditamedium">{chunk[1]}</Link> : chunk[1])}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default TechnologyAccordian
