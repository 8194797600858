import { useState } from "react"

const useToggleFaq = () => {
  const [readMore, setReadMore] = useState({})
  const [isExpanded, setIsExpanded] = useState({})
  const getToggleMeClass = (id, toggleReadMore?) => {
    if (toggleReadMore) {
      setReadMore(prev => ({
        ...prev,
        [id]: readMore[id] === undefined ? true : !readMore[id],
      }))
    } else
      setIsExpanded({
        [id]: isExpanded[id] === undefined ? true : !isExpanded[id],
      })
  }
  return {
    isExpanded,
    readMore,
    getToggleMeClass,
  }
}

export default useToggleFaq
