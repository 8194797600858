import React from "react"
import useToggleFaq from "../hooks/useToggleFaq"

import AccMinus from "../assets/img/acc-minus.inline.svg"
import AccPlus from "../assets/img/acc-plus.inline.svg"
import { Link } from "gatsby"

const TechnologyKeyTrendsAccordian = ({ keyTrends }) => {
    const { isExpanded, getToggleMeClass } = useToggleFaq()

    return (
        <section id="faq" className="">
            <div className="container px-0">
                <div className="">
                    <div className="">
                        {keyTrends.map(({ title, description }, idx) => (
                            <div
                                key={idx}
                                className={`${idx === keyTrends.length - 1 ? "" : "border-b  bg-[#1C1C1C] transition ease duration-1000"
                                    } rounded-[0.875rem] border  border-[#1C1C1C] py-[1.625rem] px-[2.5rem] mb-[1.3125rem] max-sm:p-5 ${isExpanded[idx] ? "bg-[#1C1C1C] transition ease duration-1000" : "bg-transparent"}`}
                            >
                                <span
                                    onClick={() => {
                                        getToggleMeClass(idx)
                                    }}
                                    className="flex items-center justify-between text-xl md:text-lg lg:text-[1.375rem] leading-[2rem] select-none font-gorditamedium text-white relative pr-[3.5rem] cursor-pointer"
                                >
                                    {title}
                                    <span
                                        id="showMore"
                                        className={`absolute right-0 top-2/4 translate-y-[-50%] w-[28px] h-[28px] flex justify-center items-center cursor-pointer filter invert`}
                                    >
                                        {isExpanded[idx]
                                            ? <AccMinus />
                                            : <AccPlus />
                                        }
                                    </span>
                                </span>
                                <p>{isExpanded ? isExpanded[idx] : "ffff"}</p>
                                <ul
                                    id={`desc-${idx}`}
                                    className={`mt-[1.375rem] ${isExpanded[idx] ? "" : "hidden"}`}
                                >
                                    {description.map((content, idx) => (
                                        <li key={idx} className="text-[rgba(255,255,255,0.8)] mb-[0.875rem] relative before:content-[''] before:w-[0.4375rem] before:h-[0.4375rem] before:absolute before:left-0 before:top-[0.75rem] before:bg-[#3FB955] before:rounded-full pl-5 leading-[2.125rem]">
                                            <strong><i>{content[0][1]}</i></strong>
                                            {content[1].map((chunk, idx) => chunk[0] ? <Link key={idx} to={chunk[0]} className="text-[#0B57D0] underline italic hover:text-primary font-gorditamedium">{chunk[1]}</Link> : chunk[1])}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default TechnologyKeyTrendsAccordian
