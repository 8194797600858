import { HeadFC, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect } from "react"

import ArrowWhite from "../assets/img/arrow-right.webp"
import HeroImage from "../assets/img/architecture-hero.webp"

import Contact from "../components/Contact"
import SEO from "../components/SEO"
import useSiteMetadata from "../hooks/useSiteMetadata"
import Faq from "../components/PPC/ReactDevPpc/Faq"
import { useContextValues } from "../context/NavBarContext"
import TechnologyAccordian from "../components/TechnologyServiceAccordian"
import TechnologyKeyTrendsAccordian from "../components/TechnologyKeyTrendsAccordian"

const accDataGrey = [
    {
        "title": "Rapid Initial Development",
        "description": [
            [
                "",
                "Monolithic systems are developed and deployed more quickly than any other systems because they are all housed in one codebase hence easier to manage"
            ]
        ]
    },
    {
        "title": "Easier Deployment",
        "description": [
            [
                "",
                "The deployment process is straightforward with only one executable or package compared to microservices."
            ]
        ]
    },
    {
        "title": "Centralized Resource Management",
        "description": [
            [
                "",
                "Resources like databases and servers are managed centrally making it less complicated when it comes to complex integrations"
            ]
        ]
    },
    {
        "title": "Budget-Friendly",
        "description": [
            [
                "",
                "It’s perfect for start-up firms or smaller projects as it has lower maintenance and hosting costs due to fewer servers and simpler infrastructural requirements."
            ]
        ]
    }
]

const accDataWhite = [
    {
        "title": "Scalability",
        "description": [
            [
                "",
                "Independent scalability of each microservice ensures that resources are optimally utilized."
            ]
        ]
    },
    {
        "title": "Fault Isolation",
        "description": [
            [
                "",
                "This type of architecture guarantees greater availability because if one microservice fails, it does not affect other parts of the entire system."
            ]
        ]
    },
    {
        "title": "Technology Diversity",
        "description": [
            [
                "",
                " There may be specific technologies, databases and programming languages used in different microservices which makes it flexible when selecting the right tools for particular tasks."
            ]
        ]
    },
    {
        "title": "Faster Time-to-Market",
        "description": [
            [
                "",
                "Development happens more rapidly since separate teams work on distinct services making it possible to deploy changes or add new features without interfering with the whole application."
            ]
        ]
    },
    {
        "title": "Easier Maintenance",
        "description": [
            [
                "",
                "The modularity of microservices enhances their ease of maintenance as well as upgradeability such that individual components can be upgraded or maintained without affecting the whole system."
            ]
        ]
    }
]

const nativeMobile = [
    {
        "title": "Speedy execution",
        "description": [
            [
                "",
                "Native applications are written in the machine code, therefore their execution is much faster as well as the performance is smoother. This is crucial for applications with advanced functions or high graphically demanding applications like games or AR/VR apps."
            ]
        ]
    },
    {
        "title": "Hardware integration",
        "description": [
            [
                "",
                "Native development gives full access to the hardware of devices and their sensors, such as cameras, GPS systems, accelerometers, and biometric scanners. This allows developers to make rich-functioned apps that take full advantage of the device aspects."
            ]
        ]
    },
    {
        "title": "Platform-specific Design",
        "description": [
            [
                "",
                " Native apps follow platform-specific design guidelines and UI/UX patterns (iOS or Android), which offers an easy-to-use interface that matches what users expect from this specific platform."
            ]
        ]
    },
    {
        "title": "Platform updates",
        "description": [
            [
                "",
                "Native applications get updated together with platform updates making them always compatible with new operating system features and improvements thereby avoiding compatibility hitches plus crashes."
            ]
        ]
    },
    {
        "title": "Platform specific security",
        "description": [
            [
                "",
                "The platform ensures safety for native apps through security features and protocols like encrypted data storage or secure APIs among others leading to enhanced security per se."
            ]
        ]
    },
    {
        "title": "Platform-Specific Optimization",
        "description": [
            [
                "",
                "It is possible for developers to refine their applications based on certain criteria related to specific platforms’ properties resulting into more sophisticated and perfect functioning devices overall."
            ]
        ]
    },
    {
        "title": "Use of APIs directly",
        "description": [
            [
                "",
                "The platform’s APIs and libraries can be directly accessed by native applications, granting them access to advanced features and integration not found in cross-platform frameworks."
            ]
        ]
    },
    {
        "title": "Local Data Access",
        "description": [
            [
                "",
                "The native apps can operate more effectively offline, offering better local data storage and retrieval support which enhances the user experience under low or no connectivity conditions."
            ]
        ]
    },
    {
        "title": "Dedicated Resources",
        "description": [
            [
                "",
                " In terms of extensive documentation, tools, and community support, native development tools such as Xcode for iOS and Android Studio for Android help resolve difficulties thus making it easier to find what one needs."
            ]
        ]
    }
]

const ArcKeyTrends = [
    {
        "title": "No server management",
        "description": [
            [
                [[""]],
                [
                    [
                        "",
                        "Developers concentrate on writing code while cloud provider handles the management aspect of infrastructure automatically."
                    ]
                ]
            ]
        ]
    },
    {
        "title": "Auto-scaling",
        "description": [
            [
                [[""]],
                [
                    [
                        "",
                        "Serverless applications scale automatically with user demand so you only pay for what you consume thus making it cost effective."
                    ]
                ]
            ]
        ]
    },
    {
        "title": "Faster development cycles",
        "description": [
            [
                [[""]],
                [
                    [
                        "",
                        "With no need for server management, serverless speeds up application and feature deployment."
                    ]
                ]
            ]
        ]
    },
    {
        "title": "Event driven architecture",
        "description": [
            [
                [[""]],
                [
                    [
                        "",
                        "Serverless functions trigger via some events (user actions or database changes) leading to extremely reactive and efficient applications."
                    ]
                ]
            ]
        ]
    }
]

const faqs = [
    {
        "key": "01",
        "question": "What are the key benefits of using microservices architecture over monolithic architecture?",
        "answer": [
            "Greater scalability, fault isolation and technical flexibility are provided by microservices. Each service can be developed, deployed and scaled independently allowing for easy maintenance and efficient upgrades specifically for large or complex systems."
        ]
    },
    {
        "key": "02",
        "question": "When should I choose monolithic architecture over microservices?",
        "answer": [
            "The best choice for small to moderate software or minimum viable products up there is a monolithic design because it prioritizes developing quickly, having simple systems and lower infrastructure expenses above all things. This structuring style may also be excellent if your application does not require constant updating, extensive extensibility or tough incorporations."
        ]
    },
    {
        "key": "03",
        "question": "How does Techuz implement serverless architecture for scalable solutions?",
        "answer": [
            "To create dynamic and self-scaling apps, event based frameworks appreciate AWS Lambda, Azure Functions and Google Cloud Functions that are devoid of any server management, saving on costs and enabling rapid deployment of specific functions at the time of occurrence."
        ]
    },
    {
        "key": "04",
        "question": "What is the difference between horizontal and vertical scaling in web architectures?",
        "answer": [
            "Vertical scaling involves enhancing the capability of a single server (for instance, adding more CPU or RAM), while horizontal scaling implies adding more servers to share the burden. Microservices architecture is specifically designed for horizontal scaling, since individual services can be adjusted independently to meet the fluctuating requirements."
        ]
    },
    {
        "key": "05",
        "question": "How does Techuz ensure seamless integration between different microservices in a distributed architecture?",
        "answer": [
            "In terms of communications among services, lightweight APIs and protocols such as REST or gRPC are employed together with message brokers like RabbitMQ or Kafka in event-driven architectures. Thus, it guarantees reliable and efficient data interchange between autonomous microservices thus improving system performance and resilience."
        ]
    }
]

const CompanyPage = () => {
    const { changeNavColor } = useContextValues()

    useEffect(() => {
        changeNavColor("white")
        return (() => changeNavColor("black"))
    }, [])

    return (
        <>
            <section className="banner-gradient pt-[3.75rem] md:pt-[9.6875rem] md:pb-[6.25rem] pb-[3.125rem] lg:pb-[8.5rem] bg-[#181818] relative before:content-[''] before:w-[56.5625rem] before:h-[56.5625rem] before:absolute before:bg-no-repeat before:top-[-5rem] before:right-0 z-[1] before:-z-50 before:opacity-[0.3] before:blur-[5.625rem] overflow-hidden">
                <div className="container">
                    <div className="banner-grid flex flex-wrap justify-center lg:grid lg:grid-cols-[auto_386px] grid-cols-[1fr] gap-[2.5rem] md:gap-[3.125rem] max-lg:grid-cols-[1fr_386px] max-sm:pt-[50px]">
                        <div className="grid-left order-1 lg:order-[-1]">
                            <h1 className="text-white font-blancomedium text-[2rem] lg:text-[2.625rem] xl:text-[3.25rem] leading-[1.15] mb-[1.375rem]">
                                Architecting the future of web and mobile: performance, flexibility, and innovation
                            </h1>
                            <p className="text-white text-lg leading-[1.66] font-gordita">
                                Unlock the potential of cutting-edge architectures for web and mobile apps that scale, perform, and innovate. From monolithic to microservices, and native to cross-platform mobile solutions, we build for the future.
                            </p>
                            <div className="btn-row mt-[3.125rem]">
                                <Link to="/contact-us/" className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] max-sm:px-[1.25rem] hover:bg-transparent  uppercase">Get a Free 30 Minutes Consultation
                                    <img
                                        src={ArrowWhite}
                                        alt="RightArrow"
                                        className="w-[7px] h-[14px]"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="grid-right relative flex flex-wrap items-center justify-center flex-col">
                            <img
                                src={HeroImage}
                                alt="Hero Image"
                                className=""
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="xl:pt-[6.875rem] lg:pt-[3.4375rem] lg:pb-[6.875rem] md:py-[3.125rem] py-[1.875rem] bg-[#FFF]">
                <div className="container">
                    <div className="title text-center">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium mb-5 md:max-w-[46.875rem] mx-auto">
                            Revamping your outlook with latest <span className="italic text-primary"> web/mobile structuring</span>
                        </h2>
                    </div>
                    <p className="text-black text-base leading-[1.625] text-center md:max-w-[950px] mx-auto mb-5">
                        High-performing, secure and scalable web solutions tailored specifically for your business requirements are created by Techuz. Every project achieves maximum efficiency, adaptability and extensibility through our dual use of monoliths and microservices.
                    </p>
                    <p className="text-black text-base leading-[1.625] text-center md:max-w-[950px] mx-auto mb-5">
                        Our dynamic and reliable web applications are created using front-end technologies such as React.js, Vue.js and Angular plus robust back-end frameworks including Node.js, PHP and .NET. To make sure that our data solutions provide secure and scalable storage we use MySQL, MSSQL, PostgreSQL, MongoDB, and AWS DynamoDB.
                    </p>
                    <p className="text-black text-base leading-[1.625] text-center md:max-w-[950px] mx-auto mb-5">
                        Our mobile team understands the need for mobile convenience when it comes to accessing online services thus specializes in both native and hybrid app development. We use Flutter and React Native for hybrid apps while Kotlin and Swift are used for native Android development as well as iOS.
                    </p>
                    <p className="text-black text-base leading-[1.625] text-center md:max-w-[950px] mx-auto mb-5">
                        Our cloud skills include AWS, Azure and Google Cloud and thus allow us to administer seamless deployment and scalability. No matter if your project requires a simple base system or one that is built on micro services, we employ modern resources and unparalleled methodologies in order to realize it.
                    </p>
                    <p className="text-black text-base leading-[1.625] text-center md:max-w-[950px] mx-auto mb-5">
                        We build future-proof applications which develop hand-in-hand with your company from rapidly-launching startups to companies that are looking for CI/CD pipelines powered by Docker or Kubernetes.
                    </p>
                    <StaticImage src="../assets/img/architecture.webp" alt="architecture" className="w-full object-cover object-top max-sm:h-auto mt-10" />
                </div>
            </section>

            <section className="py-[55px] lg:py-[110px] bg-[#F5F5F5]">
                <div className="container max-w-[950px] mx-auto">
                    <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium md:max-w-[46.875rem] mx-auto mb-5 text-center">Monolithic architecture</h2>
                    <p className="text-lg leading-[30px] mb-5 text-center">Monolithic architecture is the time-honored way of doing things in building web applications and it works wonders. A single gigantic application is produced with all its components tied up into one code base. It has everything, from user interface to business logic and even data management. It suits projects that put a premium on simplicity, fast development speed and easy to manage.</p>
                    <p className="text-lg leading-[30px] text-center">Monolithic architecture is the strategy adopted by Techuz for customers seeking rapid and integrated answers, particularly for tiny to medium-sized applications or MVPs. The main advantages of this approach include:</p>

                    <div className="lg:w-[795px] mx-auto mt-10">
                        <TechnologyAccordian data={accDataGrey} isEdtech={true} />
                    </div>
                </div>
            </section>

            <section className="py-[55px] lg:py-[110px]">
                <div className="container max-w-[950px] mx-auto">
                    <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium md:max-w-[46.875rem] mx-auto mb-5 text-center">Microservices architecture</h2>
                    <p className="text-lg leading-[30px] mb-5 text-center">The microservices architecture is a contemporary and versatile method for creating vast, intricate applications by dissecting them into smaller, self-sufficiently deployable services. Each microservice is responsible for a particular function; for instance, user authentication, payment processing or database management and interacts with others via simple application programming interfaces.</p>
                    <p className="text-lg leading-[30px] text-center">At <strong> Techuz </strong> , we offer microservices architecture for clients who require scalability, fault tolerance and rapid deployment cycles. This architecture is especially suitable for companies anticipating continuous growth accompanied by frequent updates and integration with third-party services. Among the major benefits of this type of architecture are:</p>

                    <div className="lg:w-[795px] mx-auto mt-10 mb-[3.75rem]">
                        <TechnologyAccordian data={accDataWhite} isEdtech={false} />
                    </div>

                    <p className="text-lg leading-[30px] text-center">Enterprises or projects that demand quick scaling and several services, as well as the capacity for gradual evolution without any major revamping are perfectly suited to microservices.</p>
                </div>
            </section>



            <section className="bg-[#343434] lg:pt-[4.0625rem] lg:pb-[4.3125rem] md:py-[3.125rem] py-[1.875rem] before:content-[''] before:w-[37.0625rem] before:h-[37.0625rem] before:absolute before:bg-no-repeat before:top-auto before:bottom-[-10.625rem] before:right-[-10rem] z-[1] before:-z-1 before:opacity-[0.3] before:blur-[5.625rem] overflow-hidden relative green-bg-gradient">
                <div className="container">
                    <div className="grid xl:grid-cols-[1fr_560px] xl:gap-[5.625rem] lg:gap-[3.125rem] md:grid-cols-[1fr_1fr] gap-[1.875rem]">
                        <div className="left-content">
                            <h2 className="text-white text-[2.125rem] max-md:text-[32px] leading-[2.625rem] lg-[1055px]:text-[2.625rem] md:leading-[45px] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">
                                Serverless architecture
                                <span className="italic text-primary"> with microservices</span>
                            </h2>

                            <p className="text-white mb-5 leading-[1.625rem]">
                                Serverless and microservices are the contemporary and budget-friendly choices for constructing extensively scalable applications. The serverless architecture enables you to create and deploy apps without having the need to manage any infrastructure; the suppliers of the cloud handle server provisioning and scaling automatically.
                            </p>
                            <p className="text-white mb-5 leading-[1.625rem]">
                                At Techuz, we use serverless frameworks such as AWS Lambda, Azure Functions and Google Cloud Functions to develop quick and event-driven architectures. Advantages of using serverless model include:
                            </p>
                            <div className="link-green inline-flex flex-wrap items-center relative gap-[0.625rem] lg:mt-4 group transition duration-500 ease-linear">
                                <Link to="/contact-us/" className="ltext-lg relative leading-[1.5] italic font-gorditamedium text-[#4BD965] before:content-[''] before:w-full before:h-[1px] before:bg-[#4BD965] before:absolute before:left-0 before:bottom-0 ">Accelerate your Development
                                </Link>
                                <img
                                    src={ArrowWhite}
                                    alt=""
                                    className="w-[7px] h-[12px] group-hover:translate-x-1 transition duration-500 ease-linear"
                                />
                            </div>
                        </div>
                        <div className="right-content">
                            <TechnologyKeyTrendsAccordian keyTrends={ArcKeyTrends} />
                        </div>
                    </div>
                </div>
            </section>



            <section className="comparison-block  lg:py-[6.875rem] md:py-[2.8125rem] py-[1.875rem]">
                <div className="container">
                    <h2 className="text-black text-3xl lg:text-[2.375rem] xl:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium mb-5 xl:mb-10 md:max-w-[46.875rem] mx-auto text-center">
                        Comparison chart web architecture
                    </h2>
                    <div className="comparison-table pt-[1.875rem] border border-1 border-[#E8E8E8] rounded-[1.25rem] justify-between overflow-hidden">
                        <div className="table-col">
                            <div className="title-grid md:grid md:grid-cols-3 md:justify-between md:gap-[30px] gap-[20px] flex flex-wrap items-center justify-center">
                                <div className="max-sm:text-center max-sm:order-2 md:order-[-1] max-sm:w-full  max-sm:border-t max-sm:border-1 max-sm:border-[#E8E8E8] max-sm:pt-5">
                                    <p className="max-sm:w-[160px] bg-[#3FB95512] inline-block text-center py-[12px] px-[22px] text-[13px] lg:text-base leading-[1.0788rem] italic uppercase font-bold rounded-[1.875rem] text-primary md:ml-[3.125rem]">CRITERIA</p>
                                </div>
                                <div className="max-sm:text-center">
                                    <p className="max-sm:w-[160px] bg-[#3FB95512] inline-block text-center py-[12px] px-[22px] text-[13px] lg:text-base leading-[1.0788rem] italic uppercase font-bold rounded-[1.875rem] text-primary">Monolithic Architecture</p>
                                </div>
                                <div className="max-sm:text-center">
                                    <p className="max-sm:w-[160px] bg-[#3FB95512] inline-block text-center py-[12px] px-[22px] text-[13px] lg:text-base leading-[1.0788rem] italic uppercase font-bold rounded-[1.875rem] text-primary">Microservice Architecture</p>
                                </div>
                            </div>
                            <div className="comp-list border-t border-t-1 border-b-[#E8E8E8] md:px-[3.125rem] md:pt-[1.875rem] bg-[#f8f7f3] mt-[1.875rem] p-5">
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Structure</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Single, unified codebase
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Decoupled, independent services communicating via APIs
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Scalability</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Complex to scale, the entire application must scale together
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Highly scalable, individual services can be scaled independently
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Deployment</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Single deployment for the entire application
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Independent deployment for each microservice
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Development Speed</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Faster initial development and deployment
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Slower initially due to managing independent services
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Complexity</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Simple to manage in the early stages
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        More complex to manage with multiple services
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Fault Isolation</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        A failure in one part can crash the entire system
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Failures in one service do not affect the entire application
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Maintenance</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Harder to maintain as the application grows
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Easier to maintain as each service can be updated separately
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Technology Flexibility</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Limited to one technology stack
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Can use different technologies for different services
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Technology Autonomy</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Less autonomy, teams must coordinate on the entire system
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        More autonomy, teams can work independently on different services
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Time to Market</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Quicker for small-scale applications
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Slower for initial setup, faster for long-term updates
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Infrastructure Costs</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Lower infrastructure costs initially
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Higher due to more resources needed for each microservice
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Best for</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Small applications, MVPs, and projects with simple needs
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Large, complex, and scalable applications needing resilience
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Examples</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Small e-commerce sites, CMS systems
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Netflix, Amazon, Uber
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-[55px] lg:py-[110px] bg-[#F5F5F5]">
                <div className="container max-w-[950px] mx-auto">
                    <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium md:max-w-[46.875rem] mx-auto mb-5 text-center">Natives mobile development</h2>
                    <p className="text-lg leading-[30px] mb-5 text-center">Techuz is a company that creates outstanding native mobile apps which utilize iOS and Android platforms maximumly. Our development process guarantees praiseworthy performance, user experience and state-of-the-art features. This strategy has the following main advantages:</p>


                    <div className="lg:w-[795px] mx-auto mt-10">
                        <TechnologyAccordian data={nativeMobile} isEdtech={true} />
                    </div>
                </div>
            </section>


            <section className="bg-[#343434] py-[3.125rem] lg:py-[4.375rem] xl:py-[6.875rem] relative before:content-[''] before:w-[52.0625rem] before:h-[52.0625rem] before:absolute before:bg-no-repeat before:right-[-12.5rem] z-[1] before:-z-1 before:opacity-[0.3] before:blur-[5.625rem] overflow-hidden green-bg-gradient before:top-1/2 before:translate-y-[-50%]">
                <div className="container">
                    <h2 className="text-white text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium mb-[1.875rem] lg:mb-10 text-center">
                        <span className="italic text-primary">Key benefits </span>  of cross-platform mobile development
                    </h2>
                    <div className="benefit-grid grid grid-cols-1 md:grid-cols-2 gap-[1.875rem] lg:max-w-[882px] lg:mx-auto relative">
                        <div className="bg-[#1C1C1C] p-[1.875rem] rounded-[0.875rem]">
                            <p className="text-[22px] leading-[23.74px] text-white font-gorditamedium mb-[1.375rem]">Cost effective</p>
                            <p className=" text-[#FFFFFFCC] relative  before:content-[''] before:w-[0.4375rem] before:h-[0.4375rem] before:absolute before:left-0 before:top-[0.375rem] before:bg-[#3FB955] before:rounded-full pl-5 "><span className="inline-block font-gorditamedium italic">Single Codebase: </span> Only one codebase should be developed for iOS and Android which leads to a reduction in both development and maintenance costs as compared to creating separate native apps.</p>
                        </div>
                        <div className="bg-[#1C1C1C] p-[1.875rem] rounded-[0.875rem]">
                            <p className="text-[22px] leading-[23.74px] text-white font-gorditamedium mb-[1.375rem]">Faster time-to-market</p>
                            <p className=" text-[#FFFFFFCC] relative  before:content-[''] before:w-[0.4375rem] before:h-[0.4375rem] before:absolute before:left-0 before:top-[0.375rem] before:bg-[#3FB955] before:rounded-full pl-5 "><span className="inline-block font-gorditamedium italic">Unified Development: </span> By creating a single app that can run on several platforms, it speeds up the process of development and deployment resulting in faster launch times.</p>
                        </div>
                        <div className="bg-[#1C1C1C] p-[1.875rem] rounded-[0.875rem]">
                            <p className="text-[22px] leading-[23.74px] text-white font-gorditamedium mb-[1.375rem]">Consistent user experience</p>
                            <p className=" text-[#FFFFFFCC] relative  before:content-[''] before:w-[0.4375rem] before:h-[0.4375rem] before:absolute before:left-0 before:top-[0.375rem] before:bg-[#3FB955] before:rounded-full pl-5 "><span className="inline-block font-gorditamedium italic">Uniform Design: </span> Keep all features similar across iOS and Android to retain the same appearance and feel.</p>
                        </div>
                        <div className="bg-[#1C1C1C] p-[1.875rem] rounded-[0.875rem]">
                            <p className="text-[22px] leading-[23.74px] text-white font-gorditamedium mb-[1.375rem]">Code reusability</p>
                            <p className=" text-[#FFFFFFCC] relative  before:content-[''] before:w-[0.4375rem] before:h-[0.4375rem] before:absolute before:left-0 before:top-[0.375rem] before:bg-[#3FB955] before:rounded-full pl-5 "><span className="inline-block font-gorditamedium italic">Sharing code base: </span> A large part of code can be reused across platforms leading to easier updates and implementation of new features.</p>
                        </div>
                        <div className="bg-[#1C1C1C] p-[1.875rem] rounded-[0.875rem]">
                            <p className="text-[22px] leading-[23.74px] text-white font-gorditamedium mb-[1.375rem]">Access to native features</p>
                            <p className=" text-[#FFFFFFCC] relative  before:content-[''] before:w-[0.4375rem] before:h-[0.4375rem] before:absolute before:left-0 before:top-[0.375rem] before:bg-[#3FB955] before:rounded-full pl-5 "><span className="inline-block font-gorditamedium italic">Native Modules: </span> Implement platform-specific functions such as camera access, GPS, and biometric authentication.</p>
                        </div>
                        <div className="bg-[#1C1C1C] p-[1.875rem] rounded-[0.875rem]">
                            <p className="text-[22px] leading-[23.74px] text-white font-gorditamedium mb-[1.375rem]">Cross-platform frameworks</p>
                            <p className=" text-[#FFFFFFCC] relative  before:content-[''] before:w-[0.4375rem] before:h-[0.4375rem] before:absolute before:left-0 before:top-[0.375rem] before:bg-[#3FB955] before:rounded-full pl-5 "><span className="inline-block font-gorditamedium italic">Framework Efficiency: </span> Utilize strong frameworks like Flutter and React Native that enhance development efficiency by providing a wide range of libraries and components.</p>
                        </div>
                        <div className="bg-[#1C1C1C] p-[1.875rem] rounded-[0.875rem]">
                            <p className="text-[22px] leading-[23.74px] text-white font-gorditamedium mb-[1.375rem]">Active community support</p>
                            <p className=" text-[#FFFFFFCC] relative  before:content-[''] before:w-[0.4375rem] before:h-[0.4375rem] before:absolute before:left-0 before:top-[0.375rem] before:bg-[#3FB955] before:rounded-full pl-5 "><span className="inline-block font-gorditamedium italic">Nature and Resources: </span> Take advantage of large, active communities and extensive libraries to provide support and tools to enhance development.</p>
                        </div>
                        <div className="bg-[#1C1C1C] p-[1.875rem] rounded-[0.875rem]">
                            <p className="text-[22px] leading-[23.74px] text-white font-gorditamedium mb-[1.375rem]">Reduced development time</p>
                            <p className=" text-[#FFFFFFCC] relative  before:content-[''] before:w-[0.4375rem] before:h-[0.4375rem] before:absolute before:left-0 before:top-[0.375rem] before:bg-[#3FB955] before:rounded-full pl-5 "><span className="inline-block font-gorditamedium italic">Rapid prototyping: </span> Applications can be developed and iteratively rapidly, enabling rapid prototyping and adaptation to market changes.</p>
                        </div>
                        <div className="bg-[#1C1C1C] p-[1.875rem] rounded-[0.875rem]">
                            <p className="text-[22px] leading-[23.74px] text-white font-gorditamedium mb-[1.375rem]">Easy configuration</p>
                            <p className=" text-[#FFFFFFCC] relative  before:content-[''] before:w-[0.4375rem] before:h-[0.4375rem] before:absolute before:left-0 before:top-[0.375rem] before:bg-[#3FB955] before:rounded-full pl-5 "><span className="inline-block font-gorditamedium italic">Single codebase management: </span> Simplify maintenance and updates by managing one codebase instead of many, ensuring easy bug fixes and feature enhancements.</p>
                        </div>
                        <div className="bg-[#1C1C1C] p-[1.875rem] rounded-[0.875rem]">
                            <p className="text-[22px] leading-[23.74px] text-white font-gorditamedium mb-[1.375rem]">Market availability is expansive</p>
                            <p className=" text-[#FFFFFFCC] relative  before:content-[''] before:w-[0.4375rem] before:h-[0.4375rem] before:absolute before:left-0 before:top-[0.375rem] before:bg-[#3FB955] before:rounded-full pl-5 "><span className="inline-block font-gorditamedium italic">Platform coverage: </span> With one development effort, you can reach a wider audience by running your app on two major mobile platforms.</p>
                        </div>
                        <div className="bg-[#1C1C1C] p-[1.875rem] rounded-[0.875rem]">
                            <p className="text-[22px] leading-[23.74px] text-white font-gorditamedium mb-[1.375rem]">Flexibility and adaptability</p>
                            <p className=" text-[#FFFFFFCC] relative  before:content-[''] before:w-[0.4375rem] before:h-[0.4375rem] before:absolute before:left-0 before:top-[0.375rem] before:bg-[#3FB955] before:rounded-full pl-5 "><span className="inline-block font-gorditamedium italic">Adapting to change: </span> Change the codebase easily in order to adapt for platform updates while ensuring consistency across all of them.</p>
                        </div>
                        <div className="bg-[#1C1C1C] p-[1.875rem] rounded-[0.875rem]">
                            <p className="text-[22px] leading-[23.74px] text-white font-gorditamedium mb-[1.375rem]">Affordable testing</p>
                            <p className=" text-[#FFFFFFCC] relative  before:content-[''] before:w-[0.4375rem] before:h-[0.4375rem] before:absolute before:left-0 before:top-[0.375rem] before:bg-[#3FB955] before:rounded-full pl-5 "><span className="inline-block font-gorditamedium italic">Integrated Testing: </span> By testing a single codebase across different platforms, this reduces time taken to test so that there is uniformity in user experience.</p>
                        </div>
                    </div>
                </div>
            </section>


            <section className="comparison-block py-[3.4375rem]">
                <div className="container">
                    <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium mb-5 lg:mb-10 md:max-w-[46.875rem] mx-auto text-center">
                        Comparison chart mobile architecture
                    </h2>
                    <div className="comparison-table pt-[1.875rem] border border-1 border-[#E8E8E8] rounded-[1.25rem] justify-between overflow-hidden">
                        <div className="table-col">
                            <div className="title-grid md:grid md:grid-cols-3 md:justify-between md:gap-[30px] gap-[20px] flex flex-wrap items-center justify-center">
                                <div className="max-sm:text-center max-sm:order-2 md:order-[-1] max-sm:w-full  max-sm:border-t max-sm:border-1 max-sm:border-[#E8E8E8] max-sm:pt-5">
                                    <p className="max-sm:w-[160px]  bg-[#3FB95512] inline-block text-center py-[12px] px-[22px] text-[13px] lg:text-base leading-[1.0788rem] italic uppercase font-bold rounded-[1.875rem] text-primary md:ml-[3.125rem]">Development</p>
                                </div>
                                <div className="max-sm:text-center">
                                    <p className="max-sm:w-[160px] bg-[#3FB95512] inline-block text-center py-[12px] px-[22px] text-[13px] lg:text-base leading-[1.0788rem] italic uppercase font-bold rounded-[1.875rem] text-primary ">Monolithic Architecture</p>
                                </div>
                                <div className="max-sm:text-center">
                                    <p className="max-sm:w-[160px] bg-[#3FB95512] inline-block text-center py-[12px] px-[22px] text-[13px] lg:text-base leading-[1.0788rem] italic uppercase font-bold rounded-[1.875rem] text-primary">Microservice Architecture</p>
                                </div>
                            </div>
                            <div className="comp-list border-t border-t-1 border-b-[#E8E8E8] md:px-[3.125rem] md:pt-[1.875rem] bg-[#f8f7f3] mt-[1.875rem] p-5">
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Development</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Separate codebases for iOS (Swift/Objective-C) and Android (Kotlin/Java)
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Single codebase shared across platforms using frameworks like Flutter or React Native
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Performance</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Best performance with direct access to device APIs and hardware
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Near-native performance with optimized access to device APIs
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>User Experience (UX)</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Excellent UX, as apps are fully optimized for each platform’s guidelines
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        High-quality UX, but some platform-specific customizations needed
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Access to Native Features</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Full access to device features (camera, sensors, etc.)
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Near-full access with built-in libraries or additional plugins
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Development Time</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Longer due to managing separate codebases
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Faster than native, with shared codebase for multiple platforms
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Cost</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Higher due to separate development efforts for iOS and Android
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Lower than native but higher than hybrid due to specific platform optimizations
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Maintainability</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Harder to maintain with two separate codebases
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Easier than native but may need platform-specific tweaks
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Updates and Time to Market</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Slower, as updates need to be done separately for each platform
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Faster than native, with a shared codebase across platforms
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Technology Stack</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        iOS: Swift, Objective-C
                                        Android: Kotlin, Java
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Flutter (Dart), React Native (JavaScript)
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Code Reusability</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        No code reusability between platforms
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        High code reusability across platforms
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>User Interface (UI) Consistency</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Platform-specific UI with perfect alignment to OS guidelines
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Near-native UI with shared components but may require platform-specific adjustments
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px]">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Best for</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Apps requiring high performance, complex animations, or deep integration with device hardware
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Most business apps, apps that need native-like performance and shared codebase for faster development
                                    </li>
                                </ul>
                                <ul className="grid sm:grid-cols-3 md:gap-[30px] pb-[1.875rem] grid-cols-[1fr_1fr] gap-[18px] ">
                                    <li className="text-base lg:text-xl leading-[27px] max-sm:col-span-2 text-center sm:text-left">
                                        <span className="inline-block relative pl-7 italic">
                                            <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span><strong>Examples</strong>
                                        </span>
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        Gaming apps, AR/VR apps, complex fintech apps
                                    </li>
                                    <li className="text-base lg:text-xl leading-[27px]">
                                        E-commerce apps, social media apps, business applications
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Faq faqData={faqs} />
            <Contact />
        </>
    )
}

export default CompanyPage

export const Head: HeadFC = ({ location }) => {
    const { siteUrl } = useSiteMetadata()
    return (
        <SEO
            title="Technical Architecture for Web and Mobile Apps: Best Practices & Strategies"
            canonical={`${siteUrl}${location.pathname}`}
            description="Discover effective technical architecture strategies for web and mobile app development. Explore best practices to optimize performance, scalability, and user experience."
        />
    )
}
